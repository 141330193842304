<template>
  <v-dialog
    v-model="modalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit Data User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <v-col cols="8">
              <v-row>
                <!-- NIP -->
                <v-col cols="12" md="4" class="mb-n8">
                  <span class="subtitle-2">NIP</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.user_nip"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>

                <!-- Nama -->
                <v-col cols="12" md="8" class="mb-n8">
                  <span class="subtitle-2">Nama</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.user_fullname"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Nama User -->
                <v-col cols="12" md="6" class="mb-n8">
                  <span class="subtitle-2">Nama User</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="editedItem.user_nama"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>

                <!-- Password User -->
                <v-col cols="12" md="6" class="mb-n8">
                  <span class="subtitle-2">Password User</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    v-model="user_pass"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    class="mt-2 input-group--focused"
                    @click:append="show = !show"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Foto -->
                <v-col cols="12" class="mb-n8">
                  <span class="subtitle-2">Foto</span>
                  <v-file-input
                    dense
                    flat
                    outlined
                    prepend-icon
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pilih Foto user"
                    append-icon="mdi-camera"
                    @change="onFile"
                    ref="avatar"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <!-- User Level -->
                <v-col cols="12">
                  <span class="subtitle-2">User Level</span>
                  <v-select
                    dense
                    flat
                    outlined
                    class="mt-2"
                    :items="refUserlvl"
                    item-value="id"
                    item-text="nama"
                    v-model="editedItem.user_lvl"
                    autocomplete="off"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="4" class="my-auto">
              <div class="mt-2 text-center">
                <v-avatar size="250">
                  <v-img :src="urlImage" class="mx-auto"></v-img>
                </v-avatar>
              </div>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import modalEdit from "@/store/user/modalEdit";
import refreshView from "@/store/user/viewUser";
import getRef from "@/helper/getRef.js";

export default {
  computed: {
    modalEdit: {
      get() {
        return modalEdit.state.modalEdit;
      },
      set(value) {
        modalEdit.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modalEdit.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async modalEdit() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refUserlvl = await getRef.Userlvl();
      this.urlImage =
        this.editedItem.user_foto === ""
          ? process.env.VUE_APP_API_BASE + "upload/userGambar/userDefault.png"
          : process.env.VUE_APP_API_BASE +
            "upload/userGambar/" +
            this.editedItem.user_foto;
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    btnLoading: true,
    show: false,

    refUserlvl: [],

    user_pass: "",
    user_foto: "",
    urlImage: "",
  }),

  methods: {
    async edit() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("user_id", this.editedItem.user_id);
      data.append("user_nip", this.editedItem.user_nip);
      data.append("user_fullname", this.editedItem.user_fullname);
      data.append("user_nama", this.editedItem.user_nama);
      data.append("user_pass", this.user_pass);
      data.append("user_foto", this.user_foto);
      data.append("user_lvl", this.editedItem.user_lvl);

      const url = process.env.VUE_APP_API_BASE + "user";
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    onFile(value) {
      this.user_foto = value;
      this.urlImage = URL.createObjectURL(this.user_foto);
    },

    closeModal() {
      this.modalEdit = false;
    },
  },
};
</script>
