<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail User</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <div class="mt-2 text-center">
            <v-avatar size="250">
              <v-img :src="getIMG(viewItem.user_foto)" class="mx-auto"></v-img>
            </v-avatar>
          </div>

          <v-row>
            <!-- NIP -->
            <v-col cols="12" md="12" class="mb-n8">
              <span class="subtitle-2">NIP</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.user_nip"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nama User -->
            <v-col cols="12" md="5" class="mb-n8">
              <span class="subtitle-2">Nama User</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.user_nama"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>

            <!-- Nama -->
            <v-col cols="12" md="7" class="mb-n8">
              <span class="subtitle-2">Nama</span>
              <v-text-field
                dense
                flat
                outlined
                class="mt-2"
                v-model="viewItem.user_fullname"
                autocomplete="off"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- User Level -->
            <v-col cols="12">
              <span class="subtitle-2">User Level</span>
              <v-select
                dense
                flat
                outlined
                class="mt-2"
                :items="refUserlvl"
                item-value="id"
                item-text="nama"
                v-model="viewItem.user_lvl"
                autocomplete="off"
                readonly
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import getRef from "@/helper/getRef.js";
import modalView from "@/store/user/modalView";

export default {
  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.user;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalView() {
      this.refUserlvl = await getRef.Userlvl();
    },
  },

  data: () => ({
    CWidth: "60%",

    refUserlvl: [],
  }),

  methods: {
    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/userGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE + "upload/userGambar/userDefault.png"
        );
      }
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
